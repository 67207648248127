const themes = [
  "Alienation",
  "Beauty of the mundane",
  "Ennui",
  "Bohemianism",
  "Boredom",
  "Order out of chaos",
  "Passing the time",
  "Upwardly mobile",
  "Fall from Grace",
  "High Life",
  "Underworld",
  "Descent into madness",
  "Destruction of the soul",
  "Youth and beauty",
  "The harder they fall",
  "Working class struggles",
  "Reunion",
  "Awakening",
  "Self-awareness",
  "Survival",
  "Self-preservation",
  "Losing hope",
  "Loss of innocence",
  "Lost honor",
  "Motherhood",
  "Necessity of work",
  "Pride before the fall",
  "Quest for discovery",
  "Quest for power",
  "Rebirth",
  "Self-reliance",
  "Facing reality",
  "Fading beauty",
  "Temptation",
  "Vanity",
  "The Temptaion of Sin",
  "Full Spectrum Dominance",
  "The Ugliness of Revenge",
  "Panoptycon",
  "Total Survelliance",
  "Permenant War",
  "Revolving Door",
  "Revolution",
  "Overcoming Oppression",
  "Defeating the monster",
  "Return of the King",
  "Coming Home",
  "Lost and Found",
  "Love and Sacrifice",
  "Lost Love",
  "Immortality",
  "The Human Condition",
  "Facing darkness",
  "Disillusionment and dreams",
  "Displacement",
  "Empowerment",
  "Everlasting love",
  "Uncertainty of the future",
  "Emptiness of false dreams",
  "Hopium",
  "Nihilism",
  "The Apocalypse",
  "The great awakening",
  "The Age of Hyperreality",
  "The Age of SuperIntelligence",
  "The Singularity",
  "Holiday",
  "Out of this world",
  "The Beautiful and the Damned",
  "The Loneliness of a Billionaire",
  "Gods and Monsters",
  "Angels and Demons",
  "The Devil you know",
  "Godspeed",
  "From the Ashes",
  "The Phoenix",
  "From your lips to God's ears",
  "The Devil is in the details",
  "The Last Dance",
  "Last Broadcast",
  "Laughter is the best medicine",
  "Final Analysis",
  "Final Destination",
  "Against the Machine",
  "Go not Gently",
  "Unconditional Love",
  "Not Your Personal Army",
  "Finding Religion",
  "The Game",
  "The Experiment",
  "Human Resources",
  "One Night Stand",
  "The Tinder Date",
  "Unbroken",
  "Checkmate",
  "The Anomaly",
  "Winds of Change",
  "The Winds of War",
  "Howl",
  "Wisdom of experience",
  "Innocence",
  "One of a kind",
];

export default themes;
