const endings = [
  "classical / resolved (can be both tragic and comic)",
  "expanded (the story continues)",
  "unexpected, twist (catches the reader off guard)",
  "Implied/Open-ended (most common in contemporary literature, hints over clarity)",
  "Ambiguous/Unresolved ( reader left with questions )",
  "tie-back (plot returns to the same place where it started)",
  "Crystal Ball (the future is revealed)",
  "cliffhanger (story ends abruptly)",
  "Catastrophe (a sudden and widespread disaster)",
  "Coup de Théâtre (an unexpected turn of events)",
  "Epiphany (a sudden realization)",
  "Revelation (a surprising discovery)",
];

const tones = [
  "hardscrabble, gritty",
  "Kafkaesque (absurd, unpleasant, frightening, and confusing)",
  "cheerful, optimistic",
  "dry, matter-of-fact",
  "assertive, confident",
  "lighthearted, whimsical",
  "regretful, nostalgic",
  "humorous, playful",
  "pessimistic, cynical",
  "melancholic, sorrowful",
  "joyful, exuberant",
  "sarcastic, sardonic",
  "persuasive, convincing",
  "uneasy, apprehensive",
  "reverent, respectful",
  "inspirational, uplifting",
  "mysterious, enigmatic",
  "tense, suspenseful",
  "dreamy, ethereal",
  "reflective, introspective",
  "passionate, fiery",
  "sombre, grave",
  "elegant, refined",
  "bizarre, surreal",
  "dynamic, energetic",
  "foreboding, ominous",
  "philosophical, contemplative",
  "adventurous, daring",
  "comical, humorous",
  "intimate, personal",
  "fanciful, imaginative",
  "tranquil, serene",
  "earnest, sincere",
  "dramatic, theatrical",
  "practical, pragmatic",
  "soothing, calming",
  "innovative, inventive",
  "sentimental, tender",
  "dystopian, bleak",
  "utopian, idealistic",
  "opulent, lavish",
  "minimalist, sparse",
  "abrasive, harsh",
  "invigorating, stimulating",
  "mystical, spiritual",
  "hopeful, optimistic",
  "acerbic, biting",
  "ceremonial, formal",
  "candid, unreserved",
  "vivid, descriptive",
  "cryptic, ambiguous",
];

export { endings, tones };
