export const conflicts = [
  "Character vs. Nature",
  "Character vs. Self",
  "Character vs. Society",
  "Character vs. Supernatural",
  "Character vs. Technology",
  "Character vs. Character",
  "Character vs. Establishment",
];

export const remotePath = "https://stringtalk.org/genstar";
