const occupation = [
  "Student",
  "Dancer",
  "Ballerina",
  "Politician",
  "Congressman",
  "Senator",
  "Ambassador",
  "CEO of a Fortune 500 Company",
  "Philanthropist",
  "Activist",
  "Soldier",
  "Oil Tycoon",
  "Venture Capitalist",
  "Royalty",
  "Duke",
  "Gold Digger",
  "Billionaire",
  "Crypto Millionaire",
  "Lotto Winner",
  "YOLO Investor",
  "Navy Seal",
  "Marine",
  "Pilot",
  "Mercenary",
  "Social Media Influencer",
  "Celebrity",
  "Kid",
  "Stay at home parent",
  "Government Official",
  "Federal Judge",
  "Government Contractor",
  "Teenager",
  "Schoolboy",
  "Schoolgirl",
  "child prodigy",
  "Volunteer",
  "News Anchor",
  "Investigative Reporter",
  "Tornado Chaser",
  "Meteorologist",
  "Weather Reporter",
  "Lobbyist",
  "Assassin",
  "Spy",
  "Retired",
  "Con Artist",
  "Consultant",
  "Copy writer",
  "Marketing coordinator",
  "Odd jobs",
  "Merchant",
  "Security Guard",
  "Secret Service Agent",
  "Artificial Intelligence",
  "Assistive Robot",
  "AI Agent",
  "Technology Evangelist",
  "Intellectual Property Lawyer",
  "Petty Thief",
  "Gangster",
  "Model",
  "Fashion Designer",
  "Hostess",
  "Waitress",
  "Waiter",
  "Bouncer",
  "Stripper",
  "Bartender",
  "International Arms Dealer",
  "Marriage Counselor",
  "Unemployed",
  "Hustler",
  "Scientist",
  "Physicist",
  "Chemist",
  "Biologist",
  "Astronomer",
  "Psychic",
  "Tarot Card Reader",
  "Fortune Teller",
  "Palm Reader",
  "Pizza Delivery Driver",
  "Stand-up Comedian",
  "Matchmaker",
  "Gig Worker",
  "Landscaper",
  "Investment Banker",
  "Software Engineer",
  "Programmer",
  "Machine learning Engineer",
  "Salesperson",
  "Entrepreneur",
  "Growth Hacker",
  "Middle Manager",
  "Project Manager",
  "Case Manager",
  "Social Worker",
  "Hacker",
  "Intern",
  "Nurse",
  "Doctor",
  "Grocery Store Clerk",
  "Ski Instructor",
  "YouTuber",
  "Professional Gamer",
  "Professional Athlete",
  "Teacher",
  "Professor",
  "Lawyer",
  "Yoga Instructor",
  "Construction Worker",
  "Electrician",
  "Plumber",
  "Welder",
  "Barista",
  "Bartender",
  "Psychologist",
  "Real Estate Agent",
  "Fast Food Worker",
  "Chef",
  "Painter",
  "Musician",
  "Actor",
  "Dancer",
  "Writer",
  "Journalist",
  "Photographer",
  "Graphic Designer",
  "Interior Designer",
  "Architect",
  "Police Officer",
  "CIA Agent",
  "FBI Agent",
  "Firefighter",
  "Private Investigator",
  "Uber Driver",
  "Airbnb Host",
];

export { occupation };
