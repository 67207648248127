export const times = [
  "2012",
  "Present day",
  "1960s",
  "September",
  "October",
  "November",
  "December",
  "January",
  "February",
  "May",
  "June",
  "July",
  "Spring",
  "Summer",
  "Tornado season",
  "Hurricane season",
  "Flood season",
  "Earthquake season",
  "Drought season",
  "Fire season",
  "Fall",
  "Winter",
  "Weekend",
  "After Midnight",
  "Dawn",
  "Twilight",
  "Dusk",
  "Sunrise",
  "Sunset",
  "Election day",
  "Christmas",
  "New Year's Eve",
  "Hanukkah",
  "Halloween",
  "Crunch time",
  "In Session",
  "After-party",
  "College",
  "High school",
  "Elementary school",
  "Happy Hour",
  "Morning Commute",
  "Evening Commute",
  "Waiting in line",
  "Waiting for the bus",
  "Waiting for the train",
  "Ordinary day",
  "Extraordinary times",
  "State of emergency",
  "Onset of war",
  "August",
  "Back to school",
  "Summer vacation",
  "Winter break",
  "Spring break",
  "Spring cleaning",
  "Summer camp",
  "Rainy season",
  "Storm season",
  "Fog season",
  "Dry season",
  "Dotcom 90s",
  "Roaring 20s",
  "Great Depression",
  "Great Recession",
  "The future",
  "Roman Empire",
  "Ancient Egypt",
  "Prohibition",
  "Civil War",
  "World War I",
  "World War II",
  "World War III",
  "Vacation",
  "Power Blackout",
];

export const locations = [
  "Garden party",
  "Grocery store",
  "Train Station",
  "Hunting Lodge",
  "Film School",
  "Cruise ship",
  "Cabin in the woods",
  "Berlin",
  "Paris",
  "London",
  "Small town",
  "Big city",
  "Suburbia",
  "Midwest",
  "Canada",
  "Off-season tourist town",
  "Bus station parking lot",
  "Dark alley",
  "Abandoned warehouse",
  "Slamdance",
  "Hackathon",
  "Tech conference",
  "Apartment",
  "Mall",
  "Basement",
  "Attic",
  "In front of laptop",
  "Glued to phone",
  "Watching TV",
  "Out walking",
  "New York",
  "Central Park",
  "Los Angeles",
  "San Francisco",
  "Tokyo",
  "Hong Kong",
  "Shanghai",
  "Beijing",
  "Moscow",
  "Mexico City",
  "Rio de Janeiro",
  "Sao Paulo",
  "Treehouse",
  "Art gallery",
  "Campus",
  "By a river",
  "On a mountain",
  "Hospital",
  "In the car",
  "On a plane",
  "Abbey",
  "Airport",
  "Alley",
  "Hallway",
  "Office building",
  "Front yard",
  "Back yard",
  "Driveway",
  "Art Gallery",
  "Art Studio",
  "Artist Colony",
  "Art Museum",
  "Subway",
  "Newspaper stand",
  "Barber Shop",
  "Baseball Stadium",
  "Basement",
  "Beach",
  "Beauty Salon",
  "Bookstore",
  "Bridge",
  "Cabin",
  "Castle",
  "Casino",
  "Cathedral",
  "Underground cavern",
  "Grotto",
  "Cemetery",
  "Hotel",
  "Cheap Motel",
  "Brothel",
  "Backseat of a car",
  "Backstage",
  "Church",
  "Circus",
  "City Dump",
  "Rooftop",
  "City Street",
  "Coal Mine",
  "Coffee House",
  "Dorm Room",
  "Concert Hall",
  "Board Room",
  "Nightclub",
  "Gentleman's Club",
  "Strip Club",
  "Country Club",
  "Dimly lit restaurant",
  "Movie Theater",
  "Distillery",
  "Meadow",
  "Parking Garage",
  "Dive Bar",
  "Sleepy Pub",
  "Downtown",
  "Club",
  "Warehouse Party",
  "Snowy Street",
  "Fishing Boat",
  "Football Stadium",
  "Fort",
  "Garden",
  "Graveyard",
  "Gymnasium",
  "Highway Rest Stop",
  "Hospital",
  "Cafe",
  "Outdoor Cafe",
  "Jazz Club",
  "Landfill",
  "Lighthouse",
  "Mansion",
  "Steamboat",
  "Mosque",
  "Nursing Home",
  "Observatory",
  "Opera House",
  "Palace",
  "Park",
  "Outer Space",
  "Priory",
  "Prison",
  "Police Station",
  "Pottery Studio",
  "Racetrack",
  "Med school",
  "Ski lodge",
];
