const plotPoints = [
  "A businessman enters the dark world of organized crime.",
  "The head of a powerful organization is advised by consultants to step down after a series of scandals.",
  "All inhabitants of earth wake up two days into the future.",
  "The protagonist learns that Planet Earth is a simulation and is sworn to secrecy.",
  "A small asteroid is collides with the moon, narrowly missing Earth.",
  "A child of a powerful politician is kidnapped.",
  "A family relative is falsely accused of a heinous crime and the protagonist must prove their innocence.",
  "A private investigator is hired to investigate a spouse's infidelity and uncovers a larger conspiracy.",
  "A group of cave explorers are pursued by an unseen, malevolent force.",
  "The protagonist learns that free will is real but very limited.",
  "A group of 40 benevolent aliens arrive on Earth and try corrupt leaders for crimes against sentient beings.",
  "A group of libertarians are elected on a platform of ending copyright. The wave of creativity is short-lived as creators lose motivation.",
  "The best friend of the protagonist turns out to be a figment of their imagination.",
  "The mirage of a happily-married couple shatters when one of them goes missing and the other is the prime suspect.",
  "A character is accused of a crime without being told what the crime is.",
  "The main character is de-banked after being wrongly accused of hate speech.",
  "The main character falls victim to a ransomware attack and must pay a ransom to get their data back.",
  "A character is evicted from their home after being unable to pay their rent.",
  "Telecom companies begin censoring cell phone calls and text messages as monitoring becomes more widespread.",
  "A new law banning internet advertising is passed, putting the internet out of reach for ordinary people who cannot afford to pay for services.",
  "A character discover that they can copy and paste objects in real life.",
  "A character discovers that they can use their mind to copy and paste texts between devices.",
  "A character believes that internet ad agencies can read their mind and sets out to prove it.",
  "The protagonist comes to a fork in the road and must choose between two paths, one of which leads to certain death.",
  "A character suffers from short-term memory loss and cannot form new memories.",
  "A mother living with her two young children in a large, isolated home finds herself is frightened by loud noises in the night.",
  "A defense attorney is forced to defend a guilty client.",
  "A mystery writer retreats to his lake cabin after learning his wife is cheating on him is visited by a stranger.",
  "Five members of a news channel disappeared while shooting an episode of a paranormal TV show in a haunted location.",
  "An outdoor high school party is broken up by the police who film the event and play it to the students' parents.",
  "A group of twenty-somethings are on a weeklong vacation when one of them goes missing.",
  "A group of college students are hanging out on the front porch of their summer rental when news that a national draft has been reinstated.",
  "An ambitious sibling visits, urging the protagonist to return to strive for more.",
  "A rich patron offers to fund the protagonist's dream project, but with a catch.",
  "Two long-time friends enter a new phase of life together and experience different outcomes.",
  "A young character wanders away from their family at the beach into a carnival maze, where they unexpectedly meets their doppelgänger.",
  "Plagued by a series of apocalyptic visions, the main character begins to hoard supplies and prepare for the end of the world.",
  "The protagonist has just committed the perfect bank robbery.",
  "The main character begins to gradually age backwards.",
  "A cop infiltrates a gang and becomes enamored with the lifestyle.",
  "An affair between a politician and a dancer is complicated by mysterious forces keeping them apart.",
  "A character accidentally gets into a time machine and travels back in time by one hour.",
  "The characters wake up from a party in Las Vegas, with no memory of the previous night and one of the group missing.",
  "A mysterious young woman seduces lonely men in the evening and comes to find out a secret about herself.",
  "A character undergoes a procedure to erase their ex from their memories.",
  "A character finds an online community where they can play truth or dare, but the dares start to become dangerous.",
  "A character's car breaks down in the middle of nowhere.",
  "An Uber driver travels cross-country giving free rides to hitchhikers along the way.",
  "A local barbershop is actually a front.",
  "A secret society is formed to protect the knowledge that Earth is a simulation.",
  "The protagonist's hidden power is 'super hearing' -- they can hear conversations through the walls.",
  "A character finds a device which allows them to watch others through their television sets.",
  "A brilliant detective engages in a battle of wits with an arch nemesis.",
  "A radio talk show host becomes involved in the life of one of his listeners.",
  "An overworked businessman falls for a high-spirited waitress who works the late shift at a local restaurant.",
  "A secret service agent is assigned to protect a prominent politician's daughter.",
  "The protagonist must vercome a monster.",
  "The protagonist goes from rag to riches.",
  "A quest for revenge.",
  "The internet is shut down.",
  "A villian has taken over the only grocery store within miles of a rural town.",
  "A prophecy that sets the fate of the characters in motion.",
  "A reunion with a long-lost love or family member.",
  "An unexpected discovery that changes the protagonist's perspective.",
  "A betrayal by a trusted friend.",
  "A twist of fate that brings two characters together.",
  "A moral dilemma that tests the protagonist's values.",
  "A moment of triumph that is short-lived.",
  "An underdog rising to a challenge.",
  "A moment of self-realization.",
];

export default plotPoints;

/*
 "An unexpected discovery that changes the protagonist's perspective.",
  "A betrayal by a trusted friend.",
  "A twist of fate that brings two characters together.",
  "A moral dilemma that tests the protagonist's values.",
  "A moment of triumph that is short-lived.",
  "An underdog rising to a challenge.",
  "A moment of self-realization.",
  "A mysterious letter with a cryptic message.",
  "A hidden treasure map that leads to unexpected consequences.",
  "A long-lost family secret revealed.",
  "A forbidden love affair in a time of societal prejudice.",
  "An unexpected inheritance that comes with a catch.",
  "A life-changing encounter with a stranger.",
  "A devastating natural disaster that tests the characters' survival skills.",
  "A journey to a distant and mythical land.",
  "A character's descent into madness.",
  "A case of mistaken identity leading to a series of comedic events.",
  "A quest for revenge that consumes the protagonist.",
  "A sudden illness that changes the course of a character's life.",
  "A time-traveling adventure to the past or future.",
  "A life-altering decision that divides the protagonist's world.",
  "A friendship that withstands the test of time.",
  "A character confronting their deepest fear.",
  "A tragic accident that haunts the characters involved.",
  "A courtroom drama with unexpected twists.",
  "A technological advancement that revolutionizes society.",
  "A double-cross in a heist or criminal enterprise.",
  "A political conspiracy that threatens a nation.",
  "A cult with mysterious rituals and a charismatic leader.",
  "A forbidden experiment with unforeseen consequences.",
  "A journey to the center of the Earth or another uncharted realm.",
  "A character's struggle with addiction.",
  "A supernatural phenomenon that disrupts the ordinary world.",
  "A time loop or recurring event that the protagonist must break.",
  "A sudden change in the weather with catastrophic effects.",
  "A prophecy that sets the fate of the characters in motion.",
  "A reunion with a long-lost love or family member.",
  "An unexpected discovery that changes the protagonist's perspective.",
  "A betrayal by a trusted friend.",
  "A twist of fate that brings two characters together.",
  "A moral dilemma that tests the protagonist's values.",
  "A moment of triumph that is short-lived.",
  "An underdog rising to a challenge.",
  "A moment of self-realization.",
  "An artifact found in an abandoned temple that grants strange powers.",
  "A character wrongly accused of a crime fighting to clear their name.",
  "A race against time to decode an ancient language and prevent a curse.",
  "A character's invention that goes viral, leading to fame and unforeseen challenges.",
  "A pact with a supernatural entity that has a high price.",
  "A society where emotions are forbidden, and a character who starts to feel.",
  "A character who wakes up with a new identity and life they don't remember choosing.",
  "A dream that starts to bleed into reality, blurring the lines between the two.",
  "A character who can see into others' dreams and discovers a secret.",
  "A heist in a high-security intergalactic museum.",
  "A character's discovery that they can manipulate time but at a cost.",
  "A diplomatic incident that spirals into an interdimensional conflict.",
  "A rebellion in a dystopian world where knowledge is power.",
  "A parallel universe where the protagonist's alter ego lives a completely different life.",
  "A character who communicates with animals and starts an interspecies dialogue.",
  "A villain who has a change of heart after encountering a child's innocence.",
  "A character who builds a sentient AI that becomes their closest ally.",
  "A world where shadows can detach from their owners and lead their own lives.",
  "A character's ability to see how small actions change the future.",
  "An enigmatic book that writes itself, a day before the events happen.",
  "A competition where the characters must outsmart a superintelligent AI.",
  "A world where music is the source of magic, and a character who discovers a forbidden melody.",
  "An old locket that allows the wearer to witness historical events.",
  "A character's struggle with a doppelganger from another world.",
  "A potion that allows one to enter the paintings they touch.",
  "A character who can speak to the elements and learns of an impending natural upheaval.",
  "A society where the night never falls and a character who dreams of stars.",
  "A character cursed to live the same day over, but each time in a different person's body.",
  "A world where every full moon, inhabitants swap lives randomly.",
  "A city that changes layout every dawn, and one character who remembers every configuration.",
  "A character who can see the future but is unable to change it.",
  "A character who can see the past but is unable to change it.",
  "A character who can see the future and the past but is unable to change either.",
  "A character who can see the future and the past and can change both.",
  "A character who can see the future and the past and can change one but not the other.",
  */
